import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GlobalData } from "../../context/globalData";
import { useEffect, useState, useRef } from "react";
import { Drawer } from "@material-ui/core";
import { definations } from "../../utils/defination";

const useStyles = makeStyles((theme) => ({
  popup: {
    position: "absolute",
    zIndex: "10000",
    width: "500px",
    backgroundColor: "white",
    border: "4px solid #d6e4ec",
    borderRadius: "20px",
    padding: "10px",
    display: "grid",
    // justifyItems: "center",
    marginTop: "40px",
    marginLeft: "70px",
    textAlign: "left",
  },
  desc: {
    fontSize: "14px",
  },
  Heading: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
  },
  mb: {
    marginBottom: "20px",
  },
  parentContainer: {
    width: "500px",
    margin: "20px",
  },
  childContainer: {
    margin: "20px",
  },
}));

function DefinaionPopup(props) {
  const classes = useStyles();
  const { matricName } = props;
  const {
    setIsDefinaionPopup,
    isDefinaionPopup,
    definationKPI,
    setDefinationKPI,
  } = useContext(GlobalData);
  const [kpiDetails, setKpiDetails] = useState();

  useEffect(() => {
    setKpiDetails(definations?.[matricName] || definations[definationKPI]);
  }, [matricName, isDefinaionPopup, definationKPI]);

  console.log(props,'Defination props')

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isDefinaionPopup}
        onClose={() => {
          setIsDefinaionPopup(false);
          setDefinationKPI();
        }}
      >
        <div className={classes.parentContainer}>
          <h6 className={classes.Heading}>
            <span>{matricName}</span>
          </h6>
          <div className={classes.childContainer}>
            <div className={classes.mb}>
              <b className={classes.mb}>Definition : </b>
              {kpiDetails?.definition?.map((item) => (
                <ul>
                  {Array?.isArray(item) ? (
                    <ul>
                      {item?.map((i) => (
                        <li>{i || "N.A."}</li>
                      ))}
                    </ul>
                  ) : (
                    <li>
                      {item.includes("static") ? (
                        <img src={item} style={{ width: "100%" }} />
                      ) : (
                        item || "N.A."
                      )}
                    </li>
                  )}
                </ul>
              ))}
            </div>
            {/* <div className={classes.mb}>
              <b>Source tool : </b>
              {kpiDetails?.tool || "N.A."}{" "}
            </div> */}
            {/* {kpiDetails?.benchmark?.length >= 0 ? ( */}
            <div className={classes.mb}>
              <b>Benchmarks :</b>{" "}
              <ul>
                {kpiDetails?.benchmark?.map((e) => {
                  <li>{e}</li>;
                })}
              </ul>
              {kpiDetails?.benchmark?.map((item) => (
                <ul>
                  {Array?.isArray(item) ? (
                    <ul>
                      {item?.map((i) => (
                        <li>{i || "N.A."}</li>
                      ))}
                    </ul>
                  ) : (
                    <li>
                      {item.includes("static") ? (
                        <img src={item} style={{ width: "100%" }} />
                      ) : (
                        item || "N.A."
                      )}
                    </li>
                  )}
                </ul>
              ))}
            </div>
            {/* ) : (
              <div className={classes.mb}>
                <b>Benchmarks :</b>{" "}
                <ul>
                  <li>{kpiDetails?.benchmark || "Not applicable"} </li>
                </ul>
              </div>
            )} */}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default DefinaionPopup;
