import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { config } from "../../Config/permission";

function ScatterChart({ ChartData, performanceMedian }) {
  console.log("Chart Data", performanceMedian);
  useEffect(() => {
    // Create chart instance
    if (!ChartData) return;
    if (!performanceMedian) return;
    var chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";

    // Create axes
    var xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.min = 0;
    xAxis.max = Math.max(...ChartData.map((dataPoint) => dataPoint.x)) + 10;
    xAxis.keepSelection = true;
    xAxis.renderer.grid.template.above = true;
    xAxis.title.text = `Utilization ${
      config.team.projectLevelTeamUtilizationPlannedTime
        ? "(Based on Planned time (hours))"
        : `% (Based on ${config.utilizationType})`
    }`;
    xAxis.renderer.minGridDistance = 40;

    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    xAxis.min = 0;
    yAxis.max = Math.max(...ChartData.map((dataPoint) => dataPoint.y)) + 10;
    yAxis.keepSelection = true;
    yAxis.renderer.grid.template.above = true;
    yAxis.title.text = `Performance % (based on ${config.type} )`;
    yAxis.renderer.minGridDistance = 40;
    // Create color series
    // top left
    // var series1 = chart.series.push(new am4charts.LineSeries());
    // series1.dataFields.valueX = "ax";
    // series1.dataFields.valueY = "ay";
    // series1.strokeOpacity = 0;
    // series1.fillOpacity = 1;
    // series1.ignoreMinMax = true;
    // series1.fill = am4core.color("#e3853c");
    // series1.data = [
    //   {
    //     ax: -1000,
    //     ay: 0,
    //   },
    //   {
    //     ax: 0,
    //     ay: 0,
    //   },
    //   {
    //     ax: 0,
    //     ay: 1000,
    //   },
    //   {
    //     ax: -1000,
    //     ay: 1000,
    //   },
    // ];

    // bottom left
    // var series2 = chart.series.push(new am4charts.LineSeries());
    // series2.dataFields.valueX = "ax";
    // series2.dataFields.valueY = "ay";
    // series2.strokeOpacity = 0;
    // series2.ignoreMinMax = true;
    // series2.fill = am4core.color("#48b2b7");
    // series2.fillOpacity = 0.9;
    // series2.data = [
    //   {
    //     ax: -1000,
    //     ay: 0,
    //   },
    //   {
    //     ax: 0,
    //     ay: 0,
    //   },
    //   {
    //     ax: 0,
    //     ay: -1000,
    //   },
    //   {
    //     ax: -1000,
    //     ay: -1000,
    //   },
    // ];

    // bottom right
    // var series3 = chart.series.push(new am4charts.LineSeries());
    // series3.dataFields.valueX = "ax";
    // series3.dataFields.valueY = "ay";
    // series3.strokeOpacity = 0;
    // series3.fill = am4core.color("#91d1da");
    // series3.ignoreMinMax = true;
    // series3.fillOpacity = 0.9;
    // series3.data = [
    //   {
    //     ax: 1000,
    //     ay: 0,
    //   },
    //   {
    //     ax: 0,
    //     ay: 0,
    //   },
    //   {
    //     ax: 0,
    //     ay: -1000,
    //   },
    //   {
    //     ax: 1000,
    //     ay: -1000,
    //   },
    // ];

    // top right
    var series4 = chart.series.push(new am4charts.LineSeries());
    series4.dataFields.valueX = "ax";
    series4.dataFields.valueY = "ay";
    series4.strokeOpacity = 0;
    series4.fill = am4core.color("#e8c634");
    series4.ignoreMinMax = true;
    series4.fillOpacity = 0.9;
    series4.data = [
      {
        ax: 10000,
        ay: -10,
      },
      {
        ax: -10,
        ay: -10,
      },
      {
        ax: -10,
        ay: 10000,
      },
      {
        ax: 10000,
        ay: 10000,
      },
    ];

    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = "x";
    series.dataFields.valueY = "y";
    series.dataFields.value = "radius";
    series.strokeOpacity = 0;

    // Create a separate LineSeries for each data point
    ChartData.forEach((dataPoint) => {
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueX = "x";
      series.dataFields.valueY = "y";
      series.dataFields.value = "radius";
      series.strokeOpacity = 0;

      var bullet = series.bullets.push(new am4core.Circle());
      // bullet.fill = am4core.color('red');
      // getColor(dataPoint, performanceMedian)
      bullet.fill = am4core.color(getColor(dataPoint, performanceMedian));
      bullet.strokeOpacity = 0;
      bullet.strokeWidth = 2;
      bullet.fillOpacity = 0.6;
      bullet.stroke = am4core.color("#ffffff");
      bullet.hiddenState.properties.opacity = 0;
      bullet.tooltipText =
        "Name: {name}\n  Utilization: {valueX.value}\n Performance: {valueY.value}";

      bullet.events.on("over", function (event) {
        var target = event.target;
        chart.cursor.triggerMove(
          { x: target.pixelX, y: target.pixelY },
          "hard"
        );
        chart.cursor.lineX.y = target.pixelY;
        chart.cursor.lineY.x = target.pixelX - chart.plotContainer.pixelWidth;
        xAxis.tooltip.disabled = false;
        yAxis.tooltip.disabled = false;
      });

      bullet.events.on("out", function (event) {
        chart.cursor.triggerMove(event.pointer.point, "none");
        chart.cursor.lineX.y = 0;
        chart.cursor.lineY.x = 0;
        xAxis.tooltip.disabled = true;
        yAxis.tooltip.disabled = true;
      });

      // Add data to the series
      series.data.push(dataPoint);
      series.heatRules.push({
        target: bullet,
        min: 2,
        max: 30,
        property: "radius",
      });
    });

    // Create label bullet to display the name
    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{name}";
    labelBullet.label.verticalCenter = "bottom";
    labelBullet.label.dy = -2; // Adjust the vertical position as needed
    labelBullet.label.fontSize = 10;
    labelBullet.label.fill = am4core.color("#000000");
    series.data = JSON.parse(JSON.stringify(ChartData));

    var label = chart.plotContainer.createChild(am4core.Label);
    label.fontSize = 60;
    label.fillOpacity = 0.4;
    label.align = "center";
    label.zIndex = 1000;

    var sliderContainer = chart.bottomAxesContainer.createChild(
      am4core.Container
    );
    sliderContainer.width = am4core.percent(300);
    sliderContainer.layout = "horizontal";
  }, [ChartData, performanceMedian]);

  return (
    <>
      <div id="chartdiv" style={{ width: "100%", height: "500px" }} />
    </>
  );
}

export default ScatterChart;

const getColor = (data, performanceMedian) => {
  const greaterMedian = performanceMedian + 20;
  const lessMedian = performanceMedian - 20;
  const greaterNormalMedian = greaterMedian - 1;
  const lessNormalMedian = lessMedian - 1;

  const xData = +data.x || 0;
  const yData = data.y || 0;

  // if (data.x > 100) {
  //   console.log('Over Utilized')
  // }
  // else if (data.x < 50) {
  //   console.log("under utilized");
  // }
  // else if (data.x > 50 && data.x <= 100) {
  //   console.log('normal')
  // }

  // if (data.y > greaterMedian) {
  //   console.log("over performance")
  // }
  // else if (data.y < lessMedian) {
  //   console.log('under performance')
  // }
  // else if (data.y > lessNormalMedian && data.y < greaterNormalMedian) {
  //   console.log('normal')
  // }

  if (xData <= 50 && yData <= lessMedian) {
    // Under utilized and Under performance
    return "red";
  } else if (yData > greaterMedian && xData > 100) {
    // Over performance Over utilized
    return "purple";
  } else if (xData <= 50 && yData > greaterNormalMedian) {
    // Under utilized and Over performance
    return "yellow";
  } else if (xData > 100 && yData <= lessMedian) {
    //  Over utilized and Under performance
    return "orange";
  } else if (
    xData > 100 &&
    yData > lessNormalMedian &&
    yData < greaterNormalMedian
  ) {
    // Over utilized and Normal performance
    return "brown";
  } else if (
    xData <= 50 &&
    yData > lessNormalMedian &&
    yData < greaterNormalMedian
  ) {
    // Under utilized and Normal performance
    return "gray";
  } else if (
    xData > 50 &&
    xData <= 100 &&
    yData > lessNormalMedian &&
    yData < greaterNormalMedian
  ) {
    // Normal utilized and Normal performance
    return "green";
  } else if (xData > 50 && xData <= 100 && yData > greaterMedian) {
    // Normal utilized and Over performance
    return "blue";
  } else if (xData > 50 && xData <= 100 && yData <= lessMedian) {
    // Normal utilized and Under performance
    return "velvet";
  }
  // else {
  //   return 'black'
  // }
};
