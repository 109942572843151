import { Card } from "@material-ui/core";
import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { Box, Button, CardContent, Popover, TextField } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import EngineeringIcon from "@mui/icons-material/Engineering";
import dollerImg from "../../assets/doller.png";

const WorkAllocationHeader = ({
  data,
  showCost,
  handleClose,
  showCostPopup,
  constAmt,
  setConstAmt,
  ApplyIssueHandler,
}) => {
  return (
    <>
      <Card className="d-flex py-2 px-3 align-items-center">
        <Box className="d-flex">
          <DescriptionIcon />
          <div className="px-2">{data?.ProjectCount} Project</div>
        </Box>
        <Box className="d-flex mx-4">
          <PeopleIcon />
          <div className="px-2">{data?.ResourceCount} People</div>
        </Box>
        <Box className="d-flex mx-4">
          <EngineeringIcon />
          <div className="px-2">{data?.TotalFte} FTE</div>
        </Box>
        <Box className="d-flex mx-4" onClick={showCost}>
          <img src={dollerImg} alt="dollerImg" width={"32px"} /> Cost $:{" "}
          {data?.Cost}
        </Box>
      </Card>
      <Popover
        open={Boolean(showCostPopup)}
        anchorEl={showCostPopup}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card>
          <CardContent>
            <div>
              <label>
                Please put your fully loaded engineers cost per Month
              </label>
              <br />
              <Box display={"flex"} alignItems={"center"} gridGap={2}>
                $
                <TextField
                  value={constAmt}
                  onChange={(e) => {
                    setConstAmt(e.target.value);
                  }}
                  style={{
                    width: "400px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </Box>
              <br />
              <Button
                disabled={constAmt ? false : true}
                onClick={() => {
                  ApplyIssueHandler();
                }}
                variant="outlined"
                color="primary"
              >
                {" "}
                Apply
              </Button>
            </div>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

export default WorkAllocationHeader;
