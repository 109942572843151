import { Box, Card, Grid, Tabs, Tab, Paper } from "@material-ui/core";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { useStyles } from "./style";
import axios from "../../../utils/axiosHelper";
import { useParams, useLocation } from "react-router-dom";
import GraphCard from "../../Project/projectDashboard/graphCard";
import WorkAlocationChart from "../../Project/projectDashboard/graphs/workAlocationChart";

import BarChart from "../../Project/projectDashboard/graphs/barChart";
import CommonTablePopup from "../../../components/models/commonTablePopup";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { JiraTable } from "../../../components/jira/tables/jiraTable";
import { ExpandJiraDynamicTable } from "../../../components/jira/tables/expandJiraDynamicTable";
import { JiraDynamicTable } from "../../../components/jira/tables/jiraDyanamicTable";
import CommonTable from "../../../components/commonTable";
import { format, subSeconds } from "date-fns";
import HeaderCard from "./header";
import BreadCrumb from "../../../components/breadCrumb/BreadCrumb";
import PaginationTable from "../../../components/jira/PaginationTable";
import DefinaionPopup from "../../../components/definationPopup/DefinaionPopup";
import { GlobalData } from "../../../context/globalData";
import InfoIcon from "@material-ui/icons/Info";
import { addSpace } from "../../../utils/spaceSeprator";
import InvestmentProfile from "./investmentProfile";
import IssueLevel from "../../Project/projectDashboard/IssueLevel";
import WorkCommitStatusAndSummary from "./workCommitStatusAndSummary";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import TrendContainer from "./trends";
import { getColumnName } from "./config";
import SprintOverview from "./OverviewSprint";
import { Button, ButtonGroup, InputBase } from "@mui/material";
import CheckSelect from "../../../components/select/CheckSelect";
import SelectInvestmentProfile from "../../../components/select/selectInvestmentProfile";
import SelectField from "../../../components/select/selectField";
import { EpicDistribution } from "../../Project/projectDashboard/epicDistribution";
import { config } from "../../../Config/permission";

function SprintDashboard() {
  const classes = useStyles();
  const { sprintId } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  const [TeamOrResource, setTeamOrResource] = useState("Team");
  const { isDefinaionPopup, setIsDefinaionPopup, setDefinationKPI } =
    useContext(GlobalData);

  const [headerChips, setHeaderChips] = useState({});
  const [sprintSpelloverData, setSprintSpellOverData] = useState([]);
  const [WorkCommitmentData, setWorkCommitmentData] = useState([]);
  const [isOpenTeam, setIsOpenTeam] = useState(false);
  const [isSummaryAndStatus, setIsSummaryAndStatus] = useState(false);
  const [isTrendOpen, setIsTrendOpen] = useState(false);
  const [selectedResourceDetail, setSelectedResourceDetail] = useState({});
  const [metricTableData, setMetricTableData] = useState([]);
  const [workCommitTableData, setWorkCommitTableData] = useState([]);
  const [selectedMetricName, setSelectedMetricName] = useState("");
  const [metricColumnName, setMetricColumnName] = useState([]);
  const [kpiName, setKpiName] = useState();
  const [isSprintSpillover, setIsSprintSpillover] = useState(false);
  const [sprintBarGraphData, setSprintBarGraphData] = useState({});
  const [sprintData, setSprintData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  // const [issueType, setIssueType] = useState();
  const [statusList, setStatusList] = useState([]);
  const [
    resourceAllocationStatusListData,
    setResourceAllocationStatusListData,
  ] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [AllTeamList, setAllTeamList] = useState([]);
  // const [isHalfSprintCompleted, setIsHalfSprintCompleted] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [PayloadState, setPayloadState] = useState({});
  const [CumulativeNumber, setCumulativeNumber] = useState("");
  const [SpeedingTransitionsRate, setSpeedingTransitionsRate] = useState("");
  const [ComponentData, setComponentData] = useState([]);
  const [selectedComponentData, setSelectedComponentData] = useState([]);
  const [SelectedResource, setSelectedResource] = useState([]);
  const [SelectedTeamMemberTeam, setSelectedTeamMemberTeam] = useState([]);
  const [selectedComponentUrl, setSelectedComponentUrl] = useState("");
  const [SelectedResourceUrl, setSelectedResourceUrl] = useState("");
  const [SelectedAllTeamUrl, setSelectedAllTeamUrl] = useState("");
  const [selectedSubStatus, setSelectedSubStatus] = useState([]);
  const [orignalMetricData, setOrignalMetricData] = useState([]);
  const [SummaryData, setSummaryData] = useState({});
  const [workCommitSearch, setWorkCommitSearch] = useState("");
  const [SelectedTeamOrResourceUrl, setSelectedTeamOrResourceUrl] =
    useState("");
  const [teamList, setTeamList] = useState({
    columnsList: [],
    data: [],
  });
  const [teamListForFilter, setTeamListForFilter] = useState([]);
  const [resoureceForFilter, setResoureceForFilter] = useState([]);
  const [spritID, setSPrintId] = useState();
  const [SprintCompletionRate, setSprintCompletionRate] = useState();
  const [getStatusSummary, setGetStatusSummary] = useState([]);
  const [getStatusSummaryUrl, setGetStatusSummaryUrl] = useState([]);
  const [sprintRateType, setSprintRateType] = useState("Work Commitment");
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [selectedResourceName, setSelectedResourceName] = useState([]);
  const [resourceName, setResourceName] = useState([]);
  const [defectLog, setDefectLog] = useState("logOne");
  useEffect(() => {
    let url = `/api/v2/teams/list/kpis?no_of_days=60&startDay=2024-04-1&endDay=2024-05-31&iteration=7&project_id=${state?.project?.id}`;
    axios
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return item?.TeamName;
        });
        setTeamListForFilter(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `/api/v2/projects/resourceAllocation/${state?.project?.id}?iteration=30&no_of_days=180&startDay=2023-12-1&endDay=2024-10-31`
      )
      .then((res) => {
        if (res?.data) {
          const data = Object.keys(res?.data).map((e) => {
            return { title: e, value: res?.data[e].toFixed() };
          });
          setResoureceForFilter(
            data.map((e) => e?.title).filter((e) => e !== "null")
          );
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, []);

  // Component Url
  useEffect(() => {
    if (selectedComponentData?.length > 0) {
      let url = " ";
      selectedComponentData.forEach((e, index) => {
        if (index === 0) {
          url += `&component=${e}`;
        } else {
          url += `&component=${e}`;
        }
      });
      setSelectedComponentUrl(url.trim(), selectedComponentData);
    } else {
      setSelectedComponentUrl("", selectedComponentData);
    }
  }, [selectedComponentData]);

  // Team Member resource Url
  useEffect(() => {
    if (SelectedResource?.length > 0) {
      let url = " ";
      SelectedResource.forEach((e, index) => {
        if (index === 0) {
          url += `&resourceName=${e}`;
        } else {
          url += `&resourceName=${e}`;
        }
      });
      setSelectedResourceUrl(url.trim(), SelectedResource);
    } else {
      setSelectedResourceUrl("", SelectedResource);
    }
  }, [SelectedResource]);

  // Team Member Team Url
  useEffect(() => {
    if (SelectedTeamMemberTeam?.length > 0) {
      let url = " ";
      SelectedTeamMemberTeam.forEach((e, index) => {
        if (index === 0) {
          url += `&teamName=${e}`;
        } else {
          url += `&teamName=${e}`;
        }
      });
      setSelectedAllTeamUrl(url.trim(), SelectedTeamMemberTeam);
    } else {
      setSelectedAllTeamUrl("", SelectedTeamMemberTeam);
    }
  }, [SelectedTeamMemberTeam]);

  useEffect(() => {
    let url = `/api/v2/teams/list/kpis?no_of_days=60&startDay=2024-04-1&endDay=2024-05-31&iteration=7&project_id=${state.project?.id}`;
    axios
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return item?.TeamName;
        });
        setAllTeamList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setSelectedTeam([]);
  }, [TeamOrResource, PayloadState]);

  const definationPopupHandler = (definationName) => {
    setKpiName(definationName);
    setIsDefinaionPopup(true);
  };

  useEffect(() => {
    if (TeamOrResource === "Team") {
      if (selectedTeam?.length > 0) {
        let url = " ";
        selectedTeam.forEach((e, index) => {
          if (index === 0) {
            url += `&teamName=${e}`;
          } else {
            url += `&teamName=${e}`;
          }
        });
        setSelectedTeamOrResourceUrl(url.trim(), selectedTeam);
      } else {
        setSelectedTeamOrResourceUrl("", selectedTeam);
      }
    } else {
      if (selectedTeam?.length > 0) {
        let url = " ";
        selectedTeam.forEach((e, index) => {
          if (index === 0) {
            url += `&resourceName=${e}`;
          } else {
            url += `&resourceName=${e}`;
          }
        });
        setSelectedTeamOrResourceUrl(url.trim(), selectedTeam);
      } else {
        setSelectedTeamOrResourceUrl("", selectedTeam);
      }
    }
  }, [selectedTeam]);

  const selectTeamOrResourceTypeHandler = (event) => {
    setTeamOrResource(event?.target?.value);
  };

  // get sprint spell over graph data
  const getSprintSpelloverData = () => {
    axios
      .get(`/api/v2/sprints/spillover/${sprintId}`)
      .then((res) => {
        if (res.data) {
          // const data = Object.keys(res.data).map((e) => {
          //   return { title: addSpace(e), value: res.data[e] };
          // });

          const data = [
            {
              title: "Work Done",
              value: state["Sprint Completion ratio"].value.toFixed(2),
            },
            {
              title: "Work Not Done",
              value: (100 - state["Sprint Completion ratio"].value).toFixed(2),
            },
          ];
          setSprintSpellOverData(data);
        }
      })
      .catch((error) => {});
  };

  const getCheckUrl = (prefix, items) => {
    if (items.length > 0) {
      return items.map((item) => `&${prefix}=${item}`).join("");
    }
    return "";
  };

  useEffect(() => {
    const url = getCheckUrl("status", getStatusSummary);
    setGetStatusSummaryUrl(url);
  }, [getStatusSummary]);

  useEffect(() => {
    let url = `/api/v2/sprints/summary?project_id=${state.project?.id}&sprint_id=${spritID}&board_id=${state.board.id}`;
    if (getStatusSummaryUrl) {
      url += getStatusSummaryUrl;
    }
    axios
      .get(url)
      .then((response) => {
        // Status graph
        const statusData = Object.keys(response?.data?.statusData).map((e) => {
          return {
            title: e,
            value: response?.data?.statusData[e],
          };
        });
        // Team Graph
        const teamData = Object.keys(response?.data?.teamData).map((e) => {
          return {
            title: e,
            value: response?.data?.teamData[e],
          };
        });
        // Status list
        const statusList = Object.keys(response?.data?.statusData).map(
          (e) => e
        );
        setSummaryData({
          statusData: statusData,
          teamData: teamData,
          statusList: statusList,
        });
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [isSummaryAndStatus, getStatusSummaryUrl]);

  const workCommitmentDataCreator = () => {
    const chartData = [];
    if (headerChips[sprintRateType]) {
      const WorkCommitment = headerChips[sprintRateType][0];
      const CompletionRate = headerChips["Sprint Completion ratio"][0];
      setSprintCompletionRate(CompletionRate);
      setSPrintId(WorkCommitment?.SprintId);
      SprintTitleDate(WorkCommitment?.SprintId);
      Object?.keys(WorkCommitment)?.forEach((item) => {
        if (
          item !== "_id" &&
          item !== "BoardId" &&
          item !== "GroupId" &&
          item !== "GroupTypeId" &&
          item !== "MetricId" &&
          item !== "SprintId" &&
          item !== "__v" &&
          item !== "Value"
          //item !== "WorkCommitment"
        ) {
          chartData.push({ work: addSpace(item), value: WorkCommitment[item] });
        }
      });

      setWorkCommitmentData(chartData);
    }
  };

  const teamStatus = (percent) => {
    if (percent < 50) {
      return "High";
    } else if (percent >= 50 && percent < 80) {
      return "medium";
    } else if (percent >= 80) {
      return "low";
    } else {
      return "low";
    }
  };

  //   get team list popup data
  const getTeamList = () => {
    let url = `/api/v2/sprints/teamMember/${spritID}?boardId=${state.board.id}`;
    if (epicData.selectedEpic.length > 0) {
      url += `&${epicData?.url.substring(1)}`;
    }
    if (selectedComponentUrl) {
      url += selectedComponentUrl;
    }
    if (SelectedAllTeamUrl) {
      url += SelectedAllTeamUrl;
    }
    if (SelectedResourceUrl) {
      url += SelectedResourceUrl;
    }
    axios
      .get(url)
      .then((res) => {
        const data = getColumnName(
          teamList,
          sprintBarGraphData?.State,
          teamStatus,
          res,
          classes,
          setIsTrendOpen,
          setSelectedResourceDetail,
          // isHalfSprintCompleted,
          sprintBarGraphData,
          history,
          state,
          definationPopupHandler
        );
        setTeamList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const teamModelHander = () => {
    getTeamList();
    setIsOpenTeam(true);
  };

  useEffect(() => {
    if (isOpenTeam) {
      setSelectedComponentData([]);
    }
  }, [isOpenTeam]);

  useEffect(() => {
    getTeamList();
  }, [epicData, selectedComponentUrl, SelectedAllTeamUrl, SelectedResourceUrl]);

  // const teamModelHander = useCallback(() => {
  //   getTeamList(spritID);
  //   setIsOpenTeam(true);
  // }, [epicData, spritID]);

  // to get header card data
  useEffect(() => {
    axios
      .get(
        `/api/v2/sprints/project/dashboard/details?board_id=${state.board.id}&sprint_id=${sprintId}`
      )
      .then((res) => {
        const data = res.data[0];
        const obj = { ...data };
        obj["Story Point Assigned"] = [
          {
            Value: data["Story Point"] ? data["Story Point"][0]?.SPAssigned : 0,
          },
        ];
        obj["Story Point Done"] = [
          {
            Value: data["Story Point"] ? data["Story Point"][0]?.SPDone : 0,
          },
        ];
        obj["Story Point Done In Time"] = [
          {
            Value: data["Story Point"]
              ? data["Story Point"][0]?.SPDoneInTime
              : 0,
          },
        ];
        obj["Story Point In Progress"] = [
          {
            Value: data["Story Point"]
              ? data["Story Point"][0]?.SPInProgress
              : 0,
          },
        ];
        obj["Story Point On Hold"] = [
          {
            Value: data["Story Point"] ? data["Story Point"][0]?.SPOnHold : 0,
          },
        ];
        //delete obj["Story Point"];
        setHeaderChips(obj);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (headerChips) {
      workCommitmentDataCreator();
    }
  }, [headerChips, sprintRateType]);

  const getMetricData = (name = "", metric = []) => {
    if (name === "") return;
    setCurrentPage(1);
    setMetricColumnName([]);
    setMetricTableData([]);
    setMetricColumnName([]);
    setIsSprintSpillover(false);
    let url = `/api/sprint/bargraph/view/details?project_id=${state?.project?.id}&board_id=${state?.board?.id}&sprint_id=${spritID}&metric_id=${metric[0]?.MetricId}`;
    if (SelectedTeamOrResourceUrl) {
      url += SelectedTeamOrResourceUrl;
    }
    axios
      .get(url)
      .then((response) => {
        const tempData = response?.data?.data;
        if (
          // name === "Ticket Quality" ||
          name === "Work Commitment" ||
          name === "Sprint Completion ratio"
        ) {
          setMetricTableData(tempData);
          setSelectedMetricName(name);
          setOrignalMetricData(tempData);
        } else if (
          name === "Avg Defect Lead Time" ||
          name === "Avg Lead Time"
        ) {
          setSelectedMetricName(name);
          setCumulativeNumber(response?.data?.data?.avgTime.toFixed());
          setSpeedingTransitionsRate(response?.data?.data?.percent.toFixed());
          let avgLeadTime = tempData.result2?.map((item) => {
            var date1 = new Date(`${item?.CreatedDate}`);
            var date2 = new Date(`${item?.ResolutionDate}`);
            var diff = (date2 - date1) / 1000;
            diff = Math.abs(Math.floor(diff));
            var days = Math.floor(diff / (24 * 60 * 60));
            return {
              // key: item["Key"],
              IssueType: item?.IssueType?.name || "No Issue Type",
              CreatedDate: item?.CreatedDate || "No Date",
              ResolutionDate: item?.ResolutionDate || "No Date",
              // LeadTime: days || "0",
              LeadTime: item.TimeElapsed || "0",
              Status: item?.Status?.name || "No Status",
              EpicName: item?.Epic?.name || "No epic linked",
              EpicKey: item?.Epic?.key || "No epic Linked",
              url: item?.url,
              Expand: {
                changeLog: item?.ChangeLog || [],
                workLog: item?.WorkLog ? item?.WorkLog : [],
              },
              UnassignedWorkInProgress: item?.UnassignedWIP || "-",
              SlowResponse: item?.SlowResponse || "-",
              SpeedyTransition: item?.speedyTransition || "-",
              Summary: item?.Summary || "-",
            };
          });
          setMetricTableData(avgLeadTime);
          createHeaderHandler(avgLeadTime);
        } else {
          setMetricTableData(tempData);
          createHeaderHandler(tempData);
          setSelectedMetricName(name);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getMetricData(PayloadState?.name, PayloadState?.metricData);
  }, [SelectedTeamOrResourceUrl]);

  const createHeaderHandler = (props) => {
    if (!props || !Array.isArray(props) || props?.length == 0) return;
    let header = [];
    let key = Object.keys(props[0]);
    if (!key) return;
    key?.map((item) => {
      if (item?.includes("_")) {
        let toBeRemove = "_";
        header?.push({ title: item?.replace(toBeRemove, ""), keyItem: item });
      } else {
        header?.push({
          title: item?.split(/(?=[A-Z])/).join(" "),
          keyItem: item,
          toggle: false,
        });
      }
    });
    setMetricColumnName(header);
  };

  const SprintTitleDate = (id) => {
    let url = `/api/sprint/bargraph/getSprint?project_id=${state.project?.id}&board_id=${state.board?.id}&sprint_id=${id}`;
    axios
      .get(url)
      .then((response) => {
        setSprintBarGraphData(response?.data);
        if (response?.data.State === "closed") {
          getSprintSpelloverData();
        } else {
          // Get the current date
          const currentDate = new Date();
          const startDate = new Date(response?.data?.startDate);
          const endDate = new Date(response?.data?.endDate);
          const timeDiff = endDate.getTime() - startDate.getTime();
          const middleDate = new Date(startDate.getTime() + timeDiff / 2);

          // Compare the current date to the middle date
          // if (currentDate.getTime() >= middleDate.getTime()) {
          //   setIsHalfSprintCompleted(true);
          // }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isSprintSpillover === true) {
      setMetricColumnName([]);
      setMetricTableData([]);
      setMetricColumnName();

      axios
        .get(`/api/v2/sprints/issueLevelSpillover/${sprintId}`)
        .then((res) => {
          setSprintData(res.data);
          setSelectedMetricName("SprintSpillover");
          setMetricColumnName([
            {
              field: "Assignee",
              title: "Assignee",
              width: "150px",
            },
            {
              field: "TeamName",
              title: "Team Name",
              width: "150px",
            },
            {
              field: "TaskId",
              title: "TaskId",
              width: "150px",
              render: (rowData) => (
                <a href={rowData.TaskLink}>{rowData.TaskId}</a>
              ),
            },
            {
              field: "TaskDetails",
              title: "Task Details",
              width: "150px",
            },
            {
              field: "TaskStatus",
              title: "Task Status",
              width: "150px",
            },
            {
              field: "CreatedTime",
              title: "Created Time",
              width: "150px",
              render: (data) => {
                return format(new Date(data?.CreatedTime), "dd-MMM-yyy");
              },
            },
          ]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isSprintSpillover]);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const viewMoreHandler = (name, list) => {
    // setIssueType(name);
    setSelectedMetricName(name);
    setStatusList(list);
  };

  const getResourceList = () => {
    axios
      .get(
        `/api/v2/projects/resourceAllocation/${state.project.id}?iteration=30&no_of_days=180&startDay=2023-12-1&endDay=2024-05-31`
      )
      .then((res) => {
        if (res.data) {
          const data = Object.keys(res.data).map((e) => {
            return e;
          });
          const result = data?.filter((e) => e !== "null");
          setAssigneeList(result);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    axios
      .get(
        `api/v2/sprints/resourcelist?project_id=${state.project?.id}&sprint_id=${spritID}&board_id=${state.board.id}`
        // project_id=1730144&board_id=140&sprint_id=557`
        // project_id=${state.project?.id}&sprint_id=${spritID}&board_id=${state.board.id}`
      )
      .then((res) => {
        if (res.data) {
          const names = res.data.map((item) => item.Name);
          setResourceName(names);
        }
      })
      .catch((err) => {});
  }, [state, spritID]);

  useEffect(() => {
    if (state?.project?.id) {
      getResourceList();
    }
  }, [state]);

  useEffect(() => {
    let url = `/api/v2/projects/componentList/${state?.project?.id}`;
    axios
      .get(url)
      .then((response) => {
        setComponentData(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [state]);

  useEffect(() => {
    if (selectedMetricName === "Work Commitment") {
      const filterData = orignalMetricData?.filter((e) => {
        return e?.fields?.status?.name === selectedSubStatus;
      });
      const data = filterData.length > 0 ? filterData : null;
      setMetricTableData(data);
    }
  }, [selectedSubStatus]);

  // useEffect(() => {
  //   if (selectedMetricName === "Work Commitment") {
  //     const filterData = orignalMetricData?.filter((e) => {
  //       return selectedResourceName.includes(e?.fields?.assignee?.displayName);
  //     });
  //     const data = filterData.length > 0 ? filterData : null;
  //     setMetricTableData(data);
  //   }
  // }, [selectedResourceName]);

  // useEffect(() => {
  //   if (selectedMetricName === "Work Commitment") {
  //     const filterData = orignalMetricData?.filter((e) => {
  //       // Status Filter for the Work Commitment
  //       const statusMatch = selectedSubStatus.includes(e?.fields?.status?.name);
  //       // Resource Filter for the Work Commitment
  //       const resourceMatch = selectedResourceName.includes(
  //         e?.fields?.assignee?.displayName
  //       );
  //       return statusMatch || resourceMatch;
  //     });

  //     const data = filterData.length > 0 ? filterData : [];
  //     setMetricTableData(data);
  //   }
  // }, [selectedSubStatus, selectedResourceName]);

  // Work Commit filter form front-end
  useEffect(() => {
    if (selectedMetricName === "Work Commitment") {
      const filterData = orignalMetricData?.filter((e) => {
        const statusMatch =
          selectedSubStatus.length === 0 ||
          selectedSubStatus.includes(e?.fields?.status?.name);
        const resourceMatch =
          selectedResourceName.length === 0 ||
          selectedResourceName.includes(e?.fields?.assignee?.displayName);

        return statusMatch && resourceMatch; // Both must match if selections are made
      });
      setMetricTableData(filterData); // No need for additional check for empty data
    }
  }, [selectedSubStatus, selectedResourceName]);

  useEffect(() => {
    if (workCommitSearch) {
      const searchByKey = orignalMetricData?.filter((item) =>
        item?.key?.includes(workCommitSearch)
      );

      const searchByResourece = orignalMetricData?.filter((item) =>
        item?.fields?.assignee?.displayName?.includes(workCommitSearch)
      );
      // Combine both search results
      const combinedResults = [
        ...new Set([...searchByKey, ...searchByResourece]),
      ];
      setMetricTableData(combinedResults);
    }
  }, [workCommitSearch]);

  //  statusNames
  useEffect(() => {
    const url = `/api/v2/statusNames/${state.project.id}`;
    axios
      .get(url)
      .then((response) => {
        setResourceAllocationStatusListData(
          response?.data?.StatusNames[0]?.Names
        );
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [state]);

  const getStatusAndSummary = () => {
    setIsSummaryAndStatus(true);
  };

  const getComponentTab = (key, id) => {
    switch (key) {
      case 1:
        return (
          <InvestmentProfile
            project={state.project?.id}
            sprintId={sprintId}
            sprintId2={id}
            boardId={state.board?.id}
          />
        );
      case 0:
        return (
          <>
            <HeaderCard
              classes={classes}
              headerChips={headerChips}
              getMetricData={(item, metric) => {
                getMetricData(item, metric);
                setPayloadState({ name: item, metricData: metric });
              }}
              teamModelHander={teamModelHander}
              setIsSprintSpillover={setIsSprintSpillover}
              sprintSpelloverData={state}
              sprintBarGraphData={sprintBarGraphData}
            />

            <Grid container spacing={2} className={classes.graphCard}>
              <Grid item xs={6}>
                <GraphCard
                  title={`Sprint completion rate (${SprintCompletionRate?.Value.toFixed()} %)`}
                  subTitle={
                    <>
                      {/* <div>Definition</div>{" "} */}
                      <div
                        className={classes.ViewMore}
                        onClick={() => {
                          viewMoreHandler("Sprint completion rate", [
                            "Done",
                            "Scope Removed",
                            "Scope Added",
                            "Assigned",
                            "Work Added Before Sprint",
                            "Work In Progress",
                            "Work Done In Time",
                          ]);
                        }}
                      >
                        View More
                      </div>
                    </>
                  }
                >
                  <DefinaionPopup matricName={kpiName} />
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    mb={2}
                    className="btnGroup"
                  >
                    <ButtonGroup size="small" aria-label="Small button group">
                      <Button
                        key="one"
                        onClick={() => setSprintRateType("Story Point2")}
                        variant={
                          sprintRateType === "Story Point2"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Story Point
                      </Button>
                      <Button
                        key="two"
                        onClick={() => setSprintRateType("Work Commitment2")}
                        variant={
                          sprintRateType === "Work Commitment2"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Issue
                      </Button>
                    </ButtonGroup>
                  </Box>
                  <BarChart data={WorkCommitmentData} />
                </GraphCard>
              </Grid>
              <Grid item xs={6}>
                <GraphCard
                  title={"Sprint Spillover"}
                  subTitle={<>{/* <div>Definition</div>{" "} */}</>}
                >
                  <DefinaionPopup matricName={kpiName} />
                  {sprintBarGraphData?.State !== "closed" ? (
                    <h6>
                      Sprint Spillover will be calculated at the end of the
                      Sprint.
                    </h6>
                  ) : (
                    <WorkAlocationChart
                      data={sprintSpelloverData}
                      showPercentage={true}
                    />
                  )}
                </GraphCard>
              </Grid>
            </Grid>
            {selectedMetricName && (
              <Card
                className={"customCard"}
                style={{ padding: 20, marginTop: 20 }}
              >
                <Box mt={6}>
                  <Box
                    display={"flex"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                  >
                    <Box display={"flex"} alignItems={"baseline"}>
                      <h3
                        style={{ cursor: "pointer", marginBottom: "30px" }}
                        className="mx-2"
                      >
                        <span
                          style={{
                            background: "rgb(164 234 246)",
                            fontSize: "15px",
                            padding: "15px",
                            borderRadius: "40px",
                          }}
                        >
                          {selectedMetricName}
                        </span>
                      </h3>
                      {selectedMetricName === "Work Commitment" && (
                        <div
                          className="mx-2 mt-1 cursorPointer"
                          onClick={() => getStatusAndSummary()}
                        >
                          <span
                            style={{
                              fontSize: "15px",
                              padding: "15px",
                              borderRadius: "40px",
                              border: "1px solid lightgray",
                            }}
                          >
                            {" "}
                            Summary
                          </span>{" "}
                        </div>
                      )}
                      <WorkCommitStatusAndSummary
                        open={isSummaryAndStatus}
                        setIsSummaryAndStatus={setIsSummaryAndStatus}
                        dataForSummary={SummaryData}
                        setGetStatusSummary={setGetStatusSummary}
                      />
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                      {selectedMetricName === "Work Commitment" && (
                        //   <SelectField
                        //     render={resourceName}
                        //     value={selectedResourceName}
                        //     setValue={(value) => {
                        //       setSelectedResourceName(value);
                        //     }}
                        //     placeholder="Select ResourceName"
                        //   />
                        <div className="">
                          <CheckSelect
                            names={resourceName}
                            setValue={(value) => {
                              setSelectedResourceName(value);
                            }}
                            value={selectedResourceName}
                            placeholder={"Select Resource"}
                          />
                        </div>
                      )}
                      {selectedMetricName === "Work Commitment" && (
                        <div className="mx-3">
                          <CheckSelect
                            names={resourceAllocationStatusListData}
                            setValue={(value) => {
                              setSelectedSubStatus(value);
                            }}
                            value={selectedSubStatus}
                            placeholder={"Select Status"}
                          />
                          {/* <SelectField
                            render={resourceAllocationStatusListData}
                            value={selectedSubStatus}
                            setValue={(value) => {
                              setSelectedSubStatus(value);
                            }}
                            placeholder="Select a status"
                          /> */}
                        </div>
                      )}
                      {selectedMetricName === "Work Commitment" && (
                        <div className="border rounded-pill px-2 py-1 mx-1 border-secondary">
                          <InputBase
                            placeholder="Search by key"
                            onChange={(e) =>
                              setWorkCommitSearch(e.target.value)
                            }
                          />
                        </div>
                      )}
                    </Box>
                  </Box>
                  {getComponent(selectedMetricName)}
                </Box>
                {selectedMetricName &&
                  selectedMetricName !== "SprintSpillover" &&
                  selectedMetricName !== "Sprint completion rate" && (
                    <PaginationTable
                      totalPosts={metricTableData?.length}
                      postsPerPage={postsPerPage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                    />
                  )}
              </Card>
            )}

            <CommonTablePopup
              title="Team Member List"
              open={isOpenTeam}
              setOpen={setIsOpenTeam}
              columns={teamList.columnsList}
              data={teamList.data}
              width={1200}
              tableOption={{
                pageSizeOptions: [5],
                pageSize: 5,
              }}
              componentList={ComponentData}
              setEpicData={setEpicData}
              setSelectedComponentData={setSelectedComponentData}
              selectedComponentData={selectedComponentData}
              resource={resoureceForFilter}
              setSelectedResource={setSelectedResource}
              SelectedResource={SelectedResource}
              allTeamMemberTeam={teamListForFilter}
              setSelectedTeamMemberTeam={setSelectedTeamMemberTeam}
              SelectedTeamMemberTeam={SelectedTeamMemberTeam}
              projectId={state.project.id}
            />
            {isTrendOpen && (
              <TrendContainer
                open={isTrendOpen}
                setOpen={setIsTrendOpen}
                selectedResourceDetail={selectedResourceDetail}
              />
            )}
          </>
        );
      case 2:
        return (
          <SprintOverview
            headerChips={headerChips}
            projectId={state.project.id}
          />
        );
      case 3:
        return (
          <EpicDistribution
            isSprint={true}
            sprintId={spritID}
            projectId={state.project.id}
          />
        );
      default:
        break;
    }
  };

  const getComponent = (key) => {
    switch (key) {
      case "Work Commitment":
      case "Sprint Completion ratio":
        return (
          <JiraTable
            metricBarData={[]}
            searchJiraIssue={metricTableData?.slice(
              firstPostIndex,
              lastPostIndex
            )}
            projectId={state.project.id}
            keysOfObject={metricColumnName}
            boardId={state.board?.id}
            sprintName={state.SprintName}
            tableLength={metricTableData?.length}
          />
        );
      case "Avg Defect Lead Time":
      case "Avg Lead Time":
        return (
          <>
            <SelectInvestmentProfile
              handleChange={selectTeamOrResourceTypeHandler}
              list={["Team", "Contributor"]}
              value={TeamOrResource}
              iteration={true}
            />

            {TeamOrResource === "Team" ? (
              <CheckSelect
                names={AllTeamList}
                setValue={(value) => {
                  setSelectedTeam(value);
                }}
                value={selectedTeam}
                placeholder="Select Team"
              />
            ) : (
              <CheckSelect
                names={assigneeList}
                setValue={(value) => {
                  setSelectedTeam(value);
                }}
                value={selectedTeam}
                placeholder="Select Contributors"
              />
            )}
            <span
              className="border rounded mx-3 p-2"
              style={{ display: "inline-block" }}
            >
              Cumulative{" "}
              {selectedMetricName === "Avg Defect Lead Time" ? "Defect " : " "}
              Lead Time: {CumulativeNumber} Days
            </span>
            <span
              className="border rounded mx-3 p-2"
              style={{ display: "inline-block" }}
            >
              Speeding Transitions Rate: {SpeedingTransitionsRate} %
            </span>

            {config.sprint.sprintLevelDefectLeadTimeFilter && (
              <Box display={"flex"} justifyContent={"flex-end"}>
                <ButtonGroup size="small" aria-label="Small button group">
                  <Button size="small">View By</Button>
                  <Button
                    onClick={() => setDefectLog("logOne")}
                    size="small"
                    variant={defectLog === "logOne" ? "contained" : "outlined"}
                  >
                    ChangeLog
                  </Button>
                  <Button
                    onClick={() => setDefectLog("logTwo")}
                    variant={defectLog === "logTwo" ? "contained" : "outlined"}
                    size="small"
                  >
                    WorkLog
                  </Button>
                </ButtonGroup>
              </Box>
            )}

            <ExpandJiraDynamicTable
              searchJiraIssue={metricTableData?.slice(
                firstPostIndex,
                lastPostIndex
              )}
              keysOfObject={metricColumnName}
              defectLog={defectLog}
            />
          </>
        );

      case "Hygine Score":
        return (
          <JiraDynamicTable
            searchJiraIssue={metricTableData?.slice(
              firstPostIndex,
              lastPostIndex
            )}
            keysOfObject={metricColumnName}
            name="jira"
          />
        );
      case "SprintSpillover":
        return <CommonTable columns={metricColumnName} data={sprintData} />;
      case "Sprint completion rate":
        return (
          <IssueLevel
            issueType={selectedMetricName}
            assigneeList={assigneeList}
            statusList={statusList}
            projectId={state.project.id}
            boardId={state.board?.id}
            sprintId={spritID}
            startDate={sprintBarGraphData?.startDate}
            endDate={sprintBarGraphData?.endDate}
            isDate={false}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <BreadCrumb
        backHandler={() => {
          history.goBack();
        }}
        list={["Sprint"]}
        active={state?.SprintName}
      />
      <Box mb={4}>
        <Paper>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
          >
            <Tab label="Sprint" />
            <Tab
              label={
                <Box display={"flex"}>
                  Investment Distribution&nbsp;
                  <span
                    onClick={() => {
                      setIsDefinaionPopup(true);
                      setDefinationKPI("Investment profiles");
                    }}
                  >
                    {" "}
                    <InfoIcon style={{ color: "gray" }} />
                  </span>
                </Box>
              }
            />
            <Tab
              label={
                <Box display={"flex"}>
                  Sprint Overview&nbsp;
                  <span
                    onClick={() => {
                      setIsDefinaionPopup(true);
                      setDefinationKPI("Sprint Overview");
                    }}
                  >
                    {" "}
                    <InfoIcon style={{ color: "gray" }} />
                  </span>
                </Box>
              }
            />
            <Tab label="Epic Distribution" />
          </Tabs>
        </Paper>
      </Box>

      {getComponentTab(value, spritID)}
    </>
  );
}

export default SprintDashboard;
