import { Box, Divider } from "@mui/material";
import React, { useContext } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ReorderIcon from "@mui/icons-material/Reorder";
import { GlobalData } from "../../../../context/globalData";
import InfoIcon from "@material-ui/icons/Info";
import php from "../../../../../src/assets/language/php.png";
import json from "../../../../../src/assets/language/json.png";
import js from "../../../../../src/assets/language/js.png";
import go from "../../../../../src/assets/language/go.png";
import css from "../../../../../src/assets/language/css.png";
import python from "../../../../../src/assets/language/python.png";
import java from "../../../../../src/assets/language/java.jpeg";

const DeveloperCoachingPopup = ({ classes, data, handleClose }) => {
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);

  const getLanguageImg = (e) => {
    console.log(e, "Name");
    switch (e._id) {
      case "php":
        return <img src={php} alt={e._id} width={"30px"} />;
      case "json":
        return <img src={json} alt={e._id} width={"30px"} />;
      case "go":
        return <img src={go} alt={e._id} width={"30px"} />;
      case "js":
        return <img src={js} alt={e._id} width={"30px"} />;
      case "scss":
      case "css":
        return <img src={css} alt={e._id} width={"30px"} />;
      case "py":
        return <img src={python} alt={e._id} width={"30px"} />;
      case "java":
        return <img src={java} alt={e._id} width={"30px"} />;
      default:
        return null;
    }
  };

  return (
    <Box overflow={"auto"}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        px={2}
        onClick={() => handleClose(null)}
      >
        <h4 className="pt-3">Developer Coaching </h4>
        <div className="pt-2">
          <HighlightOffIcon />
        </div>
      </Box>
      <Divider />
      <Box
        padding={3}
        display={"flex"}
        sx={{ maxHeight: 540, overflow: "auto" }}
      >
        <div style={{ borderRight: "1px dashed black", paddingRight: "30px" }}>
          <h5>
            Knowledge Area{" "}
            <span
              onClick={() => {
                setIsDefinaionPopup(true);
                setDefinationKPI("Knowledge Area");
              }}
            >
              <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
            </span>
          </h5>
          {data?.knowledgeArea?.map((e) => (
            <div>
              <ReorderIcon /> <b>{e?.percentage}%</b> {e?._id}.
            </div>
          ))}
        </div>
        <div style={{ marginLeft: "30px" }}>
          <h5>
            Top Language{" "}
            <span
              onClick={() => {
                setIsDefinaionPopup(true);
                setDefinationKPI("Top Language");
              }}
            >
              <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
            </span>
          </h5> 
            {data?.topLanguage?.map((e, index) => (
              <div key={index} className="mb-2">
                {getLanguageImg(e)} {"  "} {e?._id}: ({e?.percentage}%).
              </div>
            ))} 
        </div>
      </Box>
    </Box>
  );
};

export default DeveloperCoachingPopup;
