import { Box, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  trendContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: 24,
    padding: "8px",
  },
}));

const BenchmarkModelTrend = ({ showTrend, handleCloseTrend , metricName}) => {

  const styles = useStyles()
  console.log(metricName)

  return (
    <div>
      <Modal
        open={showTrend}
        onClose={handleCloseTrend}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.trendContainer}>
            <h3>{metricName?.data?._id}</h3>
            <h3>{metricName?.name}</h3>
        </Box>
      </Modal>
    </div>
  );
};

export default BenchmarkModelTrend;
