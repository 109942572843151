import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { GlobalData } from "../../context/globalData";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import axios from "../../utils/axiosHelper";
import { CircularProgress } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    top: "50%",
    left: "50%",
    transform: "translate(-50% ,-50%)",
    textAlign: "center",
  },
  root: {
    marginTop: "5px",
  },
  inputWraper: {
    textAlign: "center",
    marginBottom: "22px",
  },
  label: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#082C49",
    marginBottom: "15px",
    marginTop: "15px",
  },
  input: {
    width: "240px",
    height: "33px",
    borderRadius: 0,
  },
  btn: {
    backgroundColor: "#082c49",
    fontSize: "14px",
    padding: "0px 32px",
    color: "#fff",
    height: "40px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  btnWraper: {
    display: "flex",
    justifyContent: "space-evenly",
  },
}));

const MatricModal = () => {
  const classes = useStyles();
  const {
    metricConfigurationData,
    setmetricDetails,
    metricConfigurationFlag,
    setMetricConfigurationFlag,
    isPlanUpgradeMessage,
    setIsPlanUpgradeMessage,
    upgradeMatricFlag,
    setUpgradeMatricFlag,
  } = useContext(GlobalData);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [iterationData] = useState([
    // { iterateId: 1, name: "Daily", Target: 1 },
    { iterateId: 7, name: "Weekly", Target: 1 },
    { iterateId: 14, name: "Biweekly", Target: 1 },
    { iterateId: 30, name: "Monthly", Target: 1 },
    { iterateId: 90, name: "Quaterly", Target: 1 },
  ]);

  const validationSchema = yup.object({
    DisplayName: yup.string().required("Display name is required."),
    Iterations: yup.array().min(1, 'Select atleast one option of your interest').nullable(),  
  });

  const formik = useFormik({
    initialValues: {
      Id:"",
      Metric:"",
      DisplayName: "",
      DataSource:"",
      Iterations: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payload = {
        Id:metricConfigurationData.Id,
        DisplayName: values.DisplayName,
        Metric:metricConfigurationData["Metric Name"],
        DataSource:metricConfigurationData["Data Source"],
        Iterations: values.Iterations.map((e) => {
          return { iterateId: e.iterateId, Target: e.Target };
        }),
      };
      matricConfigHandler(payload);
    },
  });

  const matricConfigHandler = (values) => {
    setLoading(true);
    axios
      .post("/api/metrics/config/list/create", values)
      .then((result) => {
        if (result.data.sucess) {
          setLoading(false);
          setMetricConfigurationFlag(!metricConfigurationFlag);
          axios
          .get(`/api/metrics/config/list`)
          .then((result) => {
            result?.data?.data.length >= 0 ? setmetricDetails(result?.data?.data) :setmetricDetails([]);
          })
          .catch((err) => {
            console.log(err);
          });
        }
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.error);
        setLoading(false);
        setError(true);
        setTimeout(function () {
          setError(false);
        }, 3000);
        if(err?.response?.data?.error=== "LIMIT_REACHED"){
          setMetricConfigurationFlag(!metricConfigurationFlag);
          setIsPlanUpgradeMessage(!isPlanUpgradeMessage)
        }
      });
     
  };
 
  return (
    <>
      <Modal
        open={metricConfigurationFlag}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <h2 id="simple-modal-title">Metric Configuration</h2>
          <form
            noValidate
            autoComplete="off"
            className={classes.root}
            onSubmit={formik.handleSubmit}
          >
            <div className={classes.inputWraper}>
              <InputLabel htmlFor="DisplayName" className={classes.label}>
                Dispaly Name
              </InputLabel>
              <TextField
                id="DisplayName"
                name="DisplayName"
                variant="outlined"
                size="small"
                InputProps={{ classes: { input: classes.input } }}
                value={formik.values.DisplayName}
                onChange={formik.handleChange}
                error={
                  formik.touched.DisplayName &&
                  Boolean(formik.errors.DisplayName)
                }
                helperText={
                  formik.touched.DisplayName && formik.errors.DisplayName
                }
              />
            </div>

            <div className={classes.inputWraper}>
              <InputLabel htmlFor="Iterations" className={classes.label}>
                Iterations
              </InputLabel>
              <Autocomplete
                name="Iterations"
                multiple
                id="checkboxes-tags-demo"
                options={iterationData}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  formik.setFieldValue("Iterations", value);
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                style={{ width: 268, marginLeft: 32 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Iterations"
                    error={
                      formik.touched.Iterations &&
                      Boolean(formik.errors.Iterations)
                    }
                    helperText={
                      formik.touched.Iterations && formik.errors.Iterations
                    }
                  />
                )}
              />
            </div>
            <div className={classes.btnWraper}>
              <Button
                variant="contained"
                className={classes.btn}
                type="button"
                onClick={() => {
                  setMetricConfigurationFlag(!metricConfigurationFlag);
                }}
              >
                Cancel
              </Button>
              <Button variant="contained" className={classes.btn} type="submit">
                Submit
              </Button>
            </div>
          </form>

          {loading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          )}

          {error && (
            <div className={classes.alert}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default MatricModal;
