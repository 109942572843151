import React, { useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";

import { useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { config } from "../../../../Config/permission";

am4core.useTheme(am4themes_animated);


function DevTendBar({ graphData, yAxesLabel, issueType }) {
  useEffect(() => {
    if (!graphData || graphData.length === 0) {
      console.error("No data provided for the chart");
      return;
    }

    // Create chart instance
    const chart = am4core.create("trendChart", am4charts.XYChart);
    chart.data = graphData;

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.title.text = "Sprint Name";
    categoryAxis.renderer.grid.template.location = 0;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = yAxesLabel;

    // Create series
    const createSeries = (field) => {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.name = field;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "category";

      // Set the tooltip to include yAxesLabel
      series.tooltipText = ` ${yAxesLabel}: [bold]{valueY}[/]\nSprint Name: [bold]{categoryX}[/]`;

      series.stacked = true;
      series.columns.template.adapter.add("fill", function (fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });
    };

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Create series for each field except "category"
    if (graphData.length > 0) {
      Object.keys(graphData[0]).forEach((field) => {
        if (field !== "category") {
          createSeries(field);
        }
      });
    }

    // Clean up when unmounting
    return () => {
      chart.dispose();
    };
  }, [graphData, yAxesLabel]);

  return <div id="trendChart" style={{ width: "100%", height: "500px" }} />;
}

// export default DevTendBar;


const DevBarChart = ({ data }) => {
  // const chartRef = useRef(null);
  console.log(data,"data")

  useLayoutEffect(() => {
    let chart = am4core.create("barChart", am4charts.XYChart);

    // Data
    chart.data = data;

    // Add category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "sprintName";
    categoryAxis.title.text = "Metrics";

    // Add value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = `${config.type}`;

    // Create series
    const createSeries = (field, name, color) => {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "sprintName";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      // series.columns.template.fillOpacity = 0.8;

      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      // columnTemplate.strokeOpacity = 1;
      columnTemplate.fill = am4core.color(color); // Set the color of the bars
    };

    createSeries(`${config.type} Completed`, `${config.type} Completed`, "yellow"); // Change the color to your desired value
    createSeries(`${config.type} Assigned`, `${config.type} Assigned`, "lightgreen"); // Change the color to your desired value

    chart.legend = new am4charts.Legend();
    // chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id="barChart" style={{ width: "100%", height: "500px" }} />;
};

export { DevBarChart, DevTendBar };
