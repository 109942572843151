import React, { useEffect, useState } from "react";
import TrendPopup from "./trendChart";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import instance from "../../../../utils/axiosHelper";
import { config } from "../../../../Config/permission";
import YearlyLineChart from "../../../../components/utilizationVsPerformance/lineChart/yearlyLineChart";
import { DevBarChart, DevTendBar } from "./devtrendBar";

function TrendContainer({
  open,
  setOpen,
  selectedResourceDetail = {},
  isSprintOverView,
}) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid green",
      outline: "none",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 2, 2),
      top: "50%",
      left: "50%",
      transform: "translate(-50% ,-50%)",
      textAlign: "center",
      borderRadius: "20px",
      width: 1000,
      // height: "90vh",
    },
  }));
  const [data, setData] = useState([]);
  const [yAxisLabel, setAxisLabel] = useState("");
  const classes = useStyles();

  // const graphName =
  //   config.type === "Story Point"
  //     ? config.type
  //     : config.team.projectLevelTeamUtilizationPlannedTime
  //     ? "Planned time (hours)."
  //     : "Time";

  const graphName = config.type;

  const createData = (data) => {
    let axisLabel;
    setData(
      data.map((e) => {
        if (selectedResourceDetail.multiline) {
          return {
            sprintName: e?.SprintName || e.SprintId,
            [`${config.type} Completed`]:
              selectedResourceDetail.type === "Spillover"
                ? e.spSpillover
                : selectedResourceDetail.type === "story"
                ? e.taskCompleted
                : e.utilized || 0,
            [`${config.type} Assigned`]: e.taskAssigned || 0,
          };
        } else {
          return {
            category: e?.SprintName || e.SprintId,
            val:
              selectedResourceDetail.type === "Spillover"
                ? e.spSpillover
                : selectedResourceDetail.type === "story"
                ? e.taskCompleted
                : e.utilized || 0,
          };
        }
      })
    );
    // selectedResourceDetail.type === "Spillover"
    //   ? setAxisLabel(`Avg Spillover (Based on last 5 sprint avg ${graphName})`)
    //   : selectedResourceDetail.type === "story"
    //   ? setAxisLabel(`${config.type} Completed`)
    //   : setAxisLabel(
    //       `Avg Utilization (Based on last 5 sprint avg ${graphName})`
    //     );

    switch (selectedResourceDetail.type) {
      case "Spillover":
        axisLabel = `Avg Spillover (Based on last ${
          isSprintOverView ? "7" : "5"
        } sprint avg ${graphName})`;
        break;
      case "story":
        axisLabel = `${config.type} Completed`;
        break;
      default:
        axisLabel = `Avg Utilization (Based on last ${
          isSprintOverView ? "7" : "5"
        } sprint avg ${graphName})`;
        break;
    }
    setAxisLabel(axisLabel);
  };

  useEffect(() => {
    let url;
    let { Name, BoardId, AccountId, ProjectId, SprintId } =
      selectedResourceDetail;
    if (isSprintOverView) {
      url = `/api/v2/sprints/LastSevenSprintResourceTrend/?projectId=${ProjectId}&boardId=${BoardId}&teamName=${Name}`;
    } else {
      url = `/api/v2/sprints/lastFiveSprintResourceTask?projectId=${ProjectId}&boardId=${BoardId}&currentSprintId=${SprintId}&resourceId=${AccountId}`;
    }
    instance
      .get(url)
      .then((res) => {
        createData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [selectedResourceDetail]);

  return (
    <Modal
      className="paginationStyling"
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
            marginTop: "10px",
          }}
        >
          <div style={{ fontSize: "15px", fontWeight: "600" }}>
            {selectedResourceDetail.Name}
          </div>
          <div>
            <HighlightOffOutlinedIcon
              style={{
                color: "#B1B1B1",
                cursor: "pointer",
              }}
              onClick={() => setOpen(!open)}
            />
          </div>
        </div>
        {selectedResourceDetail?.multiline ? (
          <div>
            {data.length > 0 && (
              // Line Chart Code
              // <YearlyLineChart data={data} yAxisLabel={yAxisLabel} />
              <DevBarChart data={data} yAxisLabel={yAxisLabel} />
            )}
          </div>
        ) : (
          // Line chart code
          <TrendPopup data={data} yAxisLabel={yAxisLabel} />
          // <DevTendBar
          //   graphData={data}
          //   yAxesLabel={yAxisLabel}
          //   issueType={"Test"}
          //   key={'test'}
          // />
        )}
      </div>
    </Modal>
  );
}

export default TrendContainer;
