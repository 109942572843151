import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const MultipleCompareBar = ({ graphData, yAxesLabel = "Test Graph" }) => {
  console.log(graphData, "call : 1");
  useEffect(() => {
    if (graphData?.length === 0) return console.log("error: ----->graph");

    // Initialize the chart
    let chart = am4core.create("newBarGraph", am4charts.XYChart);
    chart.colors.step = 2;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "category";
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.title.text = yAxesLabel; // Use the provided label for the y-axis
    chart.scrollbarX = new am4core.Scrollbar();

    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name; // Name of the series
      series.dataFields.valueY = field; // Y-axis value
      series.dataFields.categoryX = "category"; // X-axis category
    
      // Add tooltip for the series
      series.columns.template.tooltipText = `Category: {name}\nValue: {valueY}`; // Show X and Y values
      series.columns.template.strokeWidth = 0; // Removes border for better appearance
    
      // Add label inside the bar (optional)
      let bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false; // Disable interactions for labels
      bullet.dy = 30; // Adjust vertical position of the label
      bullet.label.text = "{valueY}"; // Display the value on the bar
      bullet.label.fill = am4core.color("#ffffff"); // Label color
    
      return series;
    }

    chart.data = graphData;

    // Extract fields dynamically from the first data object
    const fields = Object?.keys(graphData[0]).filter(
      (key) => key !== "category" && typeof graphData[0][key] === "number"
    );

    // Dynamically create series for each field
    fields.forEach((field) => {
      createSeries(field, field);
    });

    function arrangeColumns() {
      let series = chart.series.getIndex(0);

      let w =
        1 -
        xAxis.renderer.cellStartLocation -
        (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          let middle = chart.series.length / 2;

          let newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          let visibleCount = newIndex;
          let newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            let trueIndex = chart.series.indexOf(series);
            let newIndex = series.dummyData;

            let dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
            series.bulletsContainer.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
          });
        }
      }
    }

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [graphData, yAxesLabel]);

  return <div id="newBarGraph" style={{ height: "600px" }} />;
};

export default MultipleCompareBar;
