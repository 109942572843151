import BreadCrumb from "../../components/breadCrumb/BreadCrumb";
import { Card } from "@material-ui/core";
import { useContext, useState, useEffect } from "react";
import { GlobalData } from "../../context/globalData";
import { useHistory } from "react-router-dom";
import Template from "../Project/pageLayout";
import { MODULE_API_URL_GEN, datePayload } from "../../utils/moduleTabApi";
import { Metric, iterationMap } from "../../utils/textMap";
import axios from "../../utils/axiosHelper";
import PointerWrapper from "../../components/overview/pointerWrapper";
import { riskMap } from "../../utils/levelMap";
import RiskWrapper from "../../components/overview/riskWrapper";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import Header from "./Header";
import { fix } from "../../utils/changeDecimal";
import GraphCard from "../Project/projectDashboard/graphCard";
import WorkAlocationChart from "../Project/projectDashboard/graphs/workAlocationChart";
import { Grid, Tooltip } from "@mui/material";
import { addSpace } from "../../utils/spaceSeprator";
import { config } from "../../Config/permission";
import Trend from "../Project/projectDashboard/trend";
import IssueLevel from "../Project/projectDashboard/IssueLevel";
import WIPTrend from "../Project/projectDashboard/trend/WIPTrend";
import AllTeamTrendBar from "../Project/projectDashboard/trend/AllTeamTrend";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles((theme) => ({
  anchor: {
    cursor: "pointer",
    color: "#5EBDCC",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  viewMore: {
    color: "#5EBDCC",
    textDecoration: "underline",
    cursor: "pointer",
  },
  // achorLine:{
  //   borderBottom:"2px solid red"
  // }
}));

export default function Teams({ date, epic, teamUrl }) {
  const { projectId } = useParams();
  const classes = useStyles();
  const [teamData, setTeamData] = useState();
  const [materialTableColums, setMaterialTableColums] = useState([]);
  const [metricValue, setMetricValue] = useState("Metric Details");
  const [kpiName, setKpiName] = useState();
  const [planningAccuracyData, setPlanningAccuracyData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [cycleData, setCycleData] = useState([]);
  const [workAllocationData, setWorkAllocationDat] = useState([]);
  const [childViewAll, setChildViewAll] = useState();
  const [AllTeamIssueType, setALlTeamIssueType] = useState();
  const [avgFlowEfficiencytrend, setAvgFlowEfficiencyTrend] = useState([]);
  const [wipAvgAgeTrend, setWipAvgAgeTrend] = useState([]);

  const {
    iterationValueGlobal,
    projectRangePickerValue: rangePickerValue,
    setProjectRangePickerValue: setRangePickerValue,
    // titleId:projectId,
    // setTitleID,
    // projectList,
    isDefinaionPopup,
    setIsDefinaionPopup,
  } = useContext(GlobalData);
  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const projectName = searchParams.get("projectName");
  const tableOption = {
    sorting: true,
    search: true,
    toolbar: true,
    fixedColumns: {
      left: 1,
      right: 0,
    },
    headerStyle: {
      backgroundColor: "#efefef",
    },
    pageSize: 5,
    paginationType: "stepped",
    searchFieldStyle: {
      padding: "5px 10px 5px 15px",
      borderRadius: "30px",
      disableUnderline: true,
      backgroundColor: "#efefef",
    },
  };
  const dateUrl = datePayload({ isCustom: false, range: "Last 90 Days" });

  const definationPopupHandler = (value) => {
    setKpiName(value);
    setIsDefinaionPopup(true);
  };

  const teamApiHandler = (url) => {
    axios
      .get(url)
      .then((result) => {
        createData(result?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //create  row data
  const createData = (result) => {
    const data = [];
    if (metricValue === "Metric Details") {
      result?.forEach((e) => {
        const columnData = {
          TeamName: e?.TeamName,
          Id: e?.Id,
          RiskLevel: e?.RiskLevel,
        };

        // Add metric names to the columnData object
        e?.metrics?.forEach((item) => {
          const targetFrom = item?.risks?.find((t) => t?.RiskLevel == 1);
          columnData[item.name] = {
            name: item?.Value,
            risk: item?.RiskLevel,
            id: item?.Id,
            From: targetFrom?.From,
            To: targetFrom?.To,
          };
        });
        data.push(columnData);
      });
    } else {
      result?.forEach((e) => {
        const columnData = {
          TeamName: e?.ProjectName,
          Id: e?.pid,
          RiskLevel: e?.riskLevel,
        };

        // Add metric names to the columnData object
        e?.MetricGroup?.forEach((item) => {
          const targetFrom = item?.risks?.find((t) => t?.RiskLevel == 1);
          columnData[item.name] = {
            risk: item?.RiskLevel,
            id: item?.MetricGroupId,
            From: targetFrom?.From,
            To: targetFrom?.To,
          };
        });
        data.push(columnData);
      });
    }
    setTeamData(data);
  };

  // Call summary and detail api
  useEffect(() => {
    if (!projectId) return;
    let TEAM_URL = MODULE_API_URL_GEN(
      rangePickerValue,
      projectId,
      iterationMap[iterationValueGlobal]
    );
    let url;
    if (metricValue === "Metric Details") {
      url = `/api/v2/teams/list/kpis?${TEAM_URL}`;
    } else {
      url = `/api/project/summary-detail?group_id=2&${TEAM_URL}`;
    }
    if (epic) {
      url += `&${epic.substring(1)}`;
    }
    teamApiHandler(url);
  }, [rangePickerValue, projectId, iterationValueGlobal, metricValue, epic]);

  const getUnits = (name) => {
    switch (name) {
      case "Total Work Delivered (Story, Task, Bug) (Count)":
      case "Stories Completed (Count)":
      case "PR Merge Frequency (Count)":
      case "Commits (Count)":
      case "Defect in Backlog (Count)":
      case "Defect in Progress (Count)":
        return "(Count)";
      case "Avg PR Resolution Time (Days) ":
      case "Defect Lead Time (Days) ":
        return "(Days)";
      case "PR Size (Code changes)":
        return "(Code Changes)";
      case "Commit Size (Line of Code)":
        return "(Line Of Code)";
      default:
        return "(%)";
    }
  };

  // create column list
  useEffect(() => {
    if (!teamData || teamData.length === 0) return;
    const columns = [
      {
        field: "TeamName",
        title: "Team",
        width: "150px",
        render: (value) => (
          <div
            className={classes.anchor}
            onClick={() =>
              history.push(
                `/project/${projectId}/teams/resources?projectName=${projectName}`,
                {
                  data: value?.TeamName,
                  teamId: value?.Id,
                }
              )
            }
          >
            {value?.TeamName} <ChevronRightIcon />
          </div>
        ),
        cellStyle: {
          color: "#5EBDCC",
          cursor: "pointer",
        },
      },
    ];

    Object.keys(teamData[0])?.forEach((item) => {
      if (
        item !== "TeamName" &&
        item !== "tableData" &&
        item !== "Id" &&
        item !== "RiskLevel"
      ) {
        if (metricValue === "Metric Details") {
          columns.push({
            title: (
              <HeaderTitle
                lablel={item}
                definationPopupHandler={definationPopupHandler}
              />
            ),
            field: item,
            width: "150px",
            render: (rowData) => (
              <Tooltip
                title={
                  <h6>
                    Target :{" "}
                    {rowData[item]?.From !== null &&
                    rowData[item]?.From !== undefined
                      ? `${rowData[item]?.From}${getUnits(item)}`
                      : " - "}
                    {" to "}
                    {rowData[item]?.To !== null &&
                    rowData[item]?.To !== undefined
                      ? `${rowData[item]?.To}${getUnits(item)}`
                      : " - "}
                  </h6>
                }
              >
                <div
                  onClick={() => onColClick(rowData, item)}
                  className={classes.anchor}
                >
                  <PointerWrapper val={riskMap[rowData[item]?.risk]}>
                    {fix(rowData[item]?.name)}<NavigateNextIcon />
                  </PointerWrapper>
                </div>
              </Tooltip>
            ),
          });
        } else {
          columns.push({
            title: item,
            field: item,
            width: "150px",

            render: (rowData) => (
              <div>
                <RiskWrapper val={riskMap[rowData[item]?.risk]}>
                  {riskMap[rowData[item]?.risk]}
                </RiskWrapper>
              </div>
            ),
          });
        }
      }
    });
    // columns.push({
    //   field: "riskLevel",
    //   title: "Overall Risk",
    //   width: "150px",
    //   render: (rowData) => (
    //     <RiskWrapper val={riskMap[rowData?.RiskLevel]}>
    //       {riskMap[rowData?.RiskLevel]}
    //     </RiskWrapper>
    //   ),
    // });
    setMaterialTableColums(columns);
  }, [teamData]);

  const onColClick = (event, item) => {
    history.push({
      pathname: `/project/${projectId}/singleTeamTrend/${event?.Id}`,
      state: { 
        matricName: item,
        matricId: `${event[item]?.id}`,
        projectName: projectName,
        teamName: `${event?.TeamName}`,
        teamId: `${event?.Id}`,
      },
    });
  };

  // This is graph which print temp use dummy api
  const getWorkBreakDown = () => {
    const dateUrl = datePayload({ isCustom: false, range: "Last 90 Days" });
    let url = `/api/v2/projects/workBreakdown/${projectId}?${dateUrl}`;
    if (epic) {
      url += `&${epic.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        const data = Object.keys(res.data.graph).map((e) => {
          return {
            title: e === "NewWork" ? "Feature and Enhancement" : addSpace(e),
            value: res.data.graph[e],
          };
        });
        setBarData({ CodeChanges: res.data.CodeChanges, data: data });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const getPlanningAccuracy = () => {
    const dateUrl = datePayload({ isCustom: false, range: "Last 90 Days" });
    let url = `/api/v2/projects/planningAccuracy/${projectId}?iteration=${30}&${dateUrl}`;
    if (epic) {
      url += `&${epic.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          const data = Object.keys(res.data?.result).map((e) => {
            return { title: e, value: res.data.result[e] };
          });
          setPlanningAccuracyData(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }; //&teamId=${}
  const getWorkAllocation = () => {
    const dateUrl = datePayload({ isCustom: false, range: "Last 90 Days" });
    let url = `/api/v2/projects/resourceAllocation/${projectId}?iteration=${30}&${dateUrl}`;
    if (epic) {
      url += `&${epic.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          const data = Object.keys(res.data).map((e) => {
            return { title: e, value: res.data[e] };
          });
          setWorkAllocationDat(data);
        }
      })
      .catch((err) => {});
  };
  const getCycletimeBreakdown = () => {
    const dateUrl = datePayload({ isCustom: false, range: "Last 90 Days" });
    let url = `/api/v2/projects/cycletimeBreakdown/${projectId}?${dateUrl}`;
    if (epic) {
      url += `&${epic.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          const response = res.data;
          const total = Object.values(response).reduce(
            (acc, value) => acc + value,
            0
          );
          // Calculate percentages and format the data
          const formattedData = Object.keys(response).map((key) => ({
            title: key,
            value: response[key].toFixed(2), // Format value to two decimal places
            percentage: ((response[key] / total) * 100).toFixed(2), // Calculate and format percentage
          }));
          setCycleData(formattedData);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (projectId) {
      getWorkBreakDown();
      getPlanningAccuracy();
      getWorkAllocation();
      getCycletimeBreakdown();
    }
  }, [projectId, epic]);
  const allTeamHandler = (data) => {
    setALlTeamIssueType(data);
  };

  useEffect(() => {
    const dateIter = datePayload(date);
    let url = `/api/v2/projects/flowEfficiencytrend/multipleTeam/${projectId}?iteration=30&${dateIter}${teamUrl}`;
    if (epic) {
      url += `&${epic.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        const AllTeamItem = response?.data?.map((e) => {
          return {
            SprintName: e?.StartDate || "N/A",
            Team:
              e?.Team?.map((team) => {
                return {
                  TeamName: team?.TeamName || "Not Assigned",
                  Efficiency: team?.Efficiency || 0,
                };
              }) || [],
          };
        });
        setAvgFlowEfficiencyTrend(AllTeamItem);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [teamUrl, date, epic]);

  useEffect(() => {
    const dateIter = datePayload(date);
    let url = `/api/v2/projects/avgAgingTrend/multipleTeam/${projectId}?iteration=30&${dateIter}${teamUrl}`;
    if (epic) {
      url += `&${epic.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        const AllTeamItem = response?.data?.map((e) => {
          return {
            SprintName: e?.StartDate || "N/A",
            Team:
              e?.Teams?.map((team) => {
                return {
                  TeamName: team?.TeamName || "Not Assigned",
                  "Work In Progress": team?.StoryPoints || 0,
                  "Avg Age": team?.Time || 0,
                };
              }) || [],
          };
        });
        setWipAvgAgeTrend(AllTeamItem);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [teamUrl, date, epic]);

  return (
    <>
      <div>
        <Header
          projectId={projectId}
          setChildViewAll={setChildViewAll}
          date={date}
          teamUrl={teamUrl}
          teamTrend={(data) => allTeamHandler(data)}
          epic={epic}
        />
        {/* {childViewAll && (
          <Grid container spacing={2} my={2} className={classes.graphCard}>
            <Grid item xs={6}>
              <GraphCard
                title={"Work Allocation"}
                subTitle={
                  <div
                    // onClick={() =>
                    //   viewMoreHandler(
                    //     "Resource Allocation",
                    //     ResourceAllocationStatusList
                    //   )
                    // }
                    className={classes.viewMore}
                  >
                    View More
                  </div>
                }
              >
                <WorkAlocationChart
                  data={workAllocationData}
                  name="ResourceAllocation"
                />
              </GraphCard>
            </Grid>
          </Grid>
        )} */}
        <Card className="resourceStyling paginationStyling customCard">
          <Template
            rangePickerValue={rangePickerValue}
            setRangePickerValue={setRangePickerValue}
            Metric={Metric}
            metricValue={metricValue}
            setMetricValue={setMetricValue}
            dropdownProjectList={[]}
            setTitleID={[]}
            projectId={""}
            moduleDetailData={teamData}
            materialTableColums={materialTableColums}
            isProject={false}
            tableOption={tableOption}
            kpiName={kpiName}
            isCompare={true}
            isUtilization={false}
            isMetricValue={false}
            title={"Team"}
          />
        </Card>
      </div>
      {["Average Flow Efficiency"].includes(AllTeamIssueType) && (
        <Card style={{ marginTop: 20 }}>
          <h4 className="m-4">{AllTeamIssueType} Trend</h4>
          <AllTeamTrendBar
            data={avgFlowEfficiencytrend}
            iteration={true}
            YAxisLabel={"Average Flow Efficiency (%)"}
          />
          {/* <Trend issueType={AllTeamIssueType} projectId={projectId} /> */}
        </Card>
      )}
      {["WIP Average Age"].includes(AllTeamIssueType) && (
        <Card style={{ marginTop: 40 }}>
          <h4 className="m-4">{AllTeamIssueType} Trend</h4>
          {/* <WIPTrend issueType={AllTeamIssueType} projectId={projectId} /> */}
          <AllTeamTrendBar
            data={wipAvgAgeTrend}
            iteration={true}
            YAxisLabel={"WIP Average Age (Days)"}
          />
        </Card>
      )}
      {AllTeamIssueType && (
        <>
          {/* {["WIP Average Age"].includes(AllTeamIssueType) && (
            <Card style={{ marginTop: 40 }}>
              <WIPTrend issueType={AllTeamIssueType} projectId={projectId} />
            </Card>
          )} */}
          {["Average Flow Efficiency"].includes(AllTeamIssueType) ? null : (
            <Card style={{ marginTop: 20 }}>
              <IssueLevel
                issueType={AllTeamIssueType}
                statusList={[]}
                subStatus={[]}
                projectId={projectId}
                autoScroll={["WIP Average Age"].includes(AllTeamIssueType)}
                isDate={
                  ["WIP Average Age"].includes(AllTeamIssueType) ? true : false
                }
                teamUrl={teamUrl}
              />
            </Card>
          )}
        </>
      )}
    </>
  );
}

const HeaderTitle = ({ lablel, definationPopupHandler }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>{lablel} </div>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => definationPopupHandler(lablel)}
      >
        <InfoIcon style={{ color: "rgb(203 196 196)" }} fontSize="small" />
      </div>
    </div>
  );
};
